import "./App.css";
import React, { Suspense } from "react";
import {
  Backdrop,
  CircularProgress,
  responsiveFontSizes,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { Routes, Route } from "react-router-dom";

/*import Home from "./pages/Home";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import NotFound from "./pages/NotFound";
import MenuBar from "./components/MenuBar";*/

const Home = React.lazy(() => import("./pages/Home"));
const Login = React.lazy(() => import("./pages/Login"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const NotFound = React.lazy(() => import("./pages/NotFound"));
const MenuBar = React.lazy(() => import("./components/MenuBar"));

function App() {
  let theme = createTheme({
    palette: {
      //mode: "dark",
      primary: {
        // light: will be calculated from palette.primary.main,
        main: "#64b5f6",
        // dark: will be calculated from palette.primary.main,
        contrastText: "#fff",
      },
    },
  });
  theme = responsiveFontSizes(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div className="App">
          <Suspense
            fallback={
              <Backdrop open>
                <CircularProgress color="primary" />
              </Backdrop>
            }
          >
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <MenuBar></MenuBar>
                    <Home />
                  </>
                }
              ></Route>
              <Route
                path="/login"
                element={
                  <>
                    <MenuBar></MenuBar>
                    <Login />
                  </>
                }
              ></Route>
              <Route path="/dashboard" element={<Dashboard />}></Route>
              <Route element={<NotFound />} path="*"></Route>
            </Routes>
          </Suspense>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
